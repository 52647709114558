var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"defaultscroll sticky",class:{ 'bg-white': _vm.isWhiteNavbar === true },attrs:{"id":"topnav"}},[_c('div',{staticClass:"container"},[_c('div',[(_vm.navLight !== true)?_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":"/images/duppla_negro_header.png","height":"24","alt":""}})]):_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{staticClass:"l-dark",attrs:{"src":"/images/duppla_negro_header.png","height":"24","alt":""}}),_c('img',{staticClass:"l-light",attrs:{"src":"/images/duppla_light_header.png","height":"24","alt":""}})])],1),(_vm.isIcons !== true && _vm.signupOptions === true)?_c('div',{staticClass:"buy-button"},[(!_vm.isAuthenticated)?_c('router-link',{staticClass:"btn",class:{
            'btn-light': _vm.navLight === true,
            'btn-primary': _vm.navLight !== true,
          },attrs:{"to":"/"}},[_vm._v("Inicia un Proceso")]):_vm._e(),(_vm.isAuthenticated)?_c('router-link',{staticClass:"btn",class:{
            'btn-light': _vm.navLight === true,
            'btn-primary': _vm.navLight !== true,
          },attrs:{"to":"/private/dashboard"}},[_vm._v("Ir a mi expediente")]):_vm._e()],1):_vm._e(),(_vm.isIcons === true)?_c('ul',{staticClass:"buy-button list-inline mb-0"},[_c('li',{staticClass:"list-inline-item mb-0 developer-icon"},[_c('b-dropdown',{attrs:{"right":"","variant":"link","toggle-class":"text-decoration-none p-0 pr-2","menu-class":"dd-menu dropdown-menu-right bg-white shadow rounded border-0 mt-3 py-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"mdi mdi-magnify h4 text-muted"})]},proxy:true}],null,false,3053606442)},[_c('div',{staticStyle:{"width":"300px"}},[_c('form',[_c('input',{staticClass:"form-control border bg-white",attrs:{"type":"text","id":"text","name":"name","placeholder":"Search..."}})])])])],1),_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),_c('div',{staticClass:"menu-extras"},[_c('div',{staticClass:"menu-item"},[_c('a',{staticClass:"navbar-toggle",class:{ open: _vm.isCondensed === true },on:{"click":function($event){return _vm.toggleMenu()}}},[_vm._m(3)])])]),_c('div',{attrs:{"id":"navigation"}},[(_vm.navOptions)?_c('ul',{staticClass:"navigation-menu",class:{ 'nav-light': _vm.navLight === true }}):_vm._e(),(_vm.signupOptions === true)?_c('div',{staticClass:"buy-menu-btn d-none"},[(!_vm.isAuthenticated)?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/"}},[_vm._v("Inicia un Proceso")]):_vm._e(),(_vm.isAuthenticated)?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/private/dashboard"}},[_vm._v("Ir a mi expediente")]):_vm._e()],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-inline-item mb-0 pr-2"},[_c('a',{staticClass:"btn btn-icon btn-soft-primary",attrs:{"href":"#"}},[_c('i',{staticClass:"mdi mdi-github mdi-18px icons"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-inline-item mb-0 pr-2"},[_c('a',{staticClass:"btn btn-icon btn-soft-primary",attrs:{"href":"#"}},[_c('i',{staticClass:"mdi mdi-stack-overflow mdi-18px icons"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-inline-item mb-0"},[_c('a',{staticClass:"btn btn-icon btn-soft-primary",attrs:{"href":"javascript:void(0)","data-toggle":"modal","data-target":"#productview"}},[_c('i',{staticClass:"mdi mdi-account-outline mdi-18px icons"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lines"},[_c('span'),_c('span'),_c('span')])
}]

export { render, staticRenderFns }